.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
}

.nav-link {
  font-weight: bold;
}

.nav-link:hover {
  color: #fff;
  background-color: #6c757d;
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg viewBox="0 0 16 16" fill="%23000" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="%23000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.5 1.5h11a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1z"/%3E%3Cpath stroke="%23000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.5 6.5l3 3m0-3l-3 3"/%3E%3C/svg%3E');
}
.img {
  display: flex;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
a {
  text-decoration: none;
}
/* button {
  border: none !important;
} */
.fontSize2 {
  color: white;
  font-size: small;
  justify-content: center;
}
.fontSize {
  font-size: small;
  justify-content: center;
}
.countercss{
  text-align: center;
  font-size: x-large;
  background-color: #ad8282;
  color: #fff;
}
.Hello {
  background-image: url('../public/DSC_2035 (1).jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.file-viewer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.fileiframe {
  width: 350;
  height: 200;
}

@media (max-width:  667px) {
  .fileiframe {
    width: 50;
  }
}